import { palette } from 'uibook';
import Copy from '../../../elements/Copy';
import { useProductsContext } from '../../../hooks/useProductsContext';

export const FilterProductCount = () => {
  const { filteredProducts } = useProductsContext();

  return (
    <Copy
      color={palette.charcoal[400]}
      fontSize={14}
      lineHeight={20}
      dataTestId="filter-product-count"
    >
      {filteredProducts.length} product{filteredProducts.length !== 1 && 's'}
    </Copy>
  );
};
