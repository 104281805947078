import styled from 'styled-components';
import { px2Rem } from '../../../utils/px2Rem';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { CSS_FONT_WEIGHT, palette } from 'uibook';

const StyledFilterHeader = styled.div`
  padding: ${px2Rem(24)} ${px2Rem(24)} 0 ${px2Rem(24)};
  max-width: 100vw;
`;

const StyledFilterHeaderMain = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100vw;
  min-height: ${px2Rem(44)};

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    align-items: center;
    min-height: none;
  }
`;

const StyledFilterHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${px2Rem(8)};
  text-wrap: nowrap;

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    flex-direction: row;
    align-items: flex-end;
    gap: ${px2Rem(16)};
  }
`;

const StyledFilterHeaderHeading = styled.h1`
  font-size: ${px2Rem(24)};
  color: ${palette.charcoal[500]};
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  margin: 0;
  padding: ${px2Rem(10)} 0;
  height: ${px2Rem(44)};

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    font-size: ${px2Rem(36)};
    padding: 0;
    height: auto;
  }
`;

const StyledFilterHeaderProductCountWrapper = styled.div`
  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    padding-bottom: ${px2Rem(3)};
  }
`;

const StyledFilterHeaderTriggerWrapper = styled.div`
  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    display: none;
  }
`;

const StyledFilterHeaderSortMenuWrapper = styled.div`
  display: none;

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    display: block;
  }
`;

export {
  StyledFilterHeader,
  StyledFilterHeaderMain,
  StyledFilterHeaderInfo,
  StyledFilterHeaderHeading,
  StyledFilterHeaderProductCountWrapper,
  StyledFilterHeaderTriggerWrapper,
  StyledFilterHeaderSortMenuWrapper,
};
