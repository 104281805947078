import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { palette } from '../../colors';

const StyledColourSwatches = styled.div<{ leftAlign?: boolean }>`
  ${({ leftAlign }) => `
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: ${leftAlign ? 'flex-start' : 'center'};

    span {
      height: ${px2Rem(12)};
      width: ${px2Rem(12)};
      border-radius: 50%;
      margin-left: ${px2Rem(leftAlign ? 0 : 6)};
      margin-right: ${px2Rem(leftAlign ? 12 : 6)};
      border: 1px solid ${palette.charcoal[200]};
    }
  `}
`;

export { StyledColourSwatches };
