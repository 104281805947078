import { getImageProps } from 'next/image';
import { ColourSwatches, SkeletonLoadingBlock, Typography, Badge, CSS_FONT_WEIGHT } from 'uibook';
import { Card } from './types';
import { StyledProductCard } from './styles';
import { cardImageSizes, getCardImageUrl } from './constants';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { VariantCondition } from '@/graphql/generated/graphql';
import { imgixLoader } from '../../utils/imgixLoader';

const ProductCardContent = ({
  productVariantConditions,
  make,
  displayName,
  cardDisplaySubtitle,
  url,
  displayPrice,
  colours,
  shouldDisplayPrices,
  variantSlug,
  isPriorityImage,
  priceSuffix,
}: Card) => {
  const productCardImageSharedProps = {
    alt: displayName ?? '',
    loader: imgixLoader,
    priority: isPriorityImage,
    quality: 30,
  };

  /**
   * `next/image` likes to create a wide range of `srcSet` values, but we only want to use the
   * initial width and double the width, as we know that the image will be displayed at that width
   * on the page. Mobile is only displayed at the `cardImageSizes.mobile.width` width, and desktop
   * is only displayed at the `cardImageSizes.desktop.width` width.
   *
   * This allows us to reduce the amount of HTML rendered for the PLP page, as we only generate the
   * exact srcSet values that are needed.
   */
  const createOverridingSrcSet = (url: string | undefined, device: 'mobile' | 'desktop') => {
    const initialWidth = cardImageSizes[device].width;

    /** Generate `1x` and `2x` image urls */
    return [initialWidth, initialWidth * 2]
      .map((width) => {
        return [
          imgixLoader({
            src: getCardImageUrl(url, device),
            width: width,
            quality: productCardImageSharedProps.quality,
          }),
          `${width}w`,
        ].join(' ');
      })
      .join(', ');
  };

  const { props: desktopImageProps } = getImageProps({
    ...productCardImageSharedProps,
    width: cardImageSizes.desktop.width,
    height: cardImageSizes.desktop.height,
    src: getCardImageUrl(url, 'desktop'),
    sizes: `${cardImageSizes.desktop.width}px`,
  });
  const { props: mobileImageProps } = getImageProps({
    ...productCardImageSharedProps,
    width: cardImageSizes.mobile.width,
    height: cardImageSizes.mobile.height,
    src: getCardImageUrl(url, 'mobile'),
    sizes: `${cardImageSizes.mobile.width}px`,
  });

  /** Use these srcSet values to override the `srcSet` values generated by `next/image` */
  const mobileImageSrcSet = createOverridingSrcSet(url, 'mobile');
  const desktopImageSrcSet = createOverridingSrcSet(url, 'desktop');

  return (
    <StyledProductCard
      data-testid={`desktop-card-${variantSlug}`}
      $hasMoreThanOneColour={colours.length > 1}
    >
      <div className="card-title">
        <Typography bold>{make}</Typography>
        <Typography bold>{displayName}</Typography>
      </div>
      {cardDisplaySubtitle && <Typography variant="body2">{cardDisplaySubtitle}</Typography>}
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <div className="image-container">
          <picture>
            <source
              media={`(min-width: ${NEW_BREAKPOINTS.smallDesktop}px)`}
              srcSet={desktopImageSrcSet}
              sizes={desktopImageProps.sizes}
            />
            <img
              {...mobileImageProps}
              srcSet={mobileImageSrcSet}
              alt={productCardImageSharedProps.alt}
              style={{ width: '100%', height: 'auto' }}
            />
          </picture>
        </div>
        {colours.length > 1 ? (
          <ColourSwatches
            dataTestId="colour-swatches"
            colours={colours}
            className="colour-swatches"
          />
        ) : null}
        <div className="condition-badge-wrapper">
          {productVariantConditions.includes(VariantCondition.New) && (
            <Badge
              type="rounded"
              fontWeight={CSS_FONT_WEIGHT.vars.regular}
              color="lightGrey"
              fontSize={12}
              textTransform="capitalize"
              dataTestId="condition-badge-new"
            >
              Brand New
            </Badge>
          )}
          {productVariantConditions.includes(VariantCondition.Refurbished) && (
            <Badge
              type="rounded"
              fontWeight={CSS_FONT_WEIGHT.vars.regular}
              color="lightGrey"
              fontSize={12}
              textTransform="capitalize"
              dataTestId="condition-badge-refurbished"
            >
              Refurbished
            </Badge>
          )}
        </div>
        {shouldDisplayPrices ? (
          <div className="price-wrapper">
            <Typography variant="body2" bold>
              <span>From</span> £{displayPrice}/mo {priceSuffix && <span>{priceSuffix}</span>}
            </Typography>
          </div>
        ) : (
          <SkeletonLoadingBlock
            dataTestId={`${variantSlug}-loading`}
            $height={18}
            $borderRadius={5}
          />
        )}
      </div>
    </StyledProductCard>
  );
};

export default ProductCardContent;
