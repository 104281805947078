import styled from 'styled-components';
import { px2Rem } from '../../../utils/px2Rem';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { ASSETS_SVGS_BASE_URL, CSS_FONT_WEIGHT, palette } from 'uibook';

const StyledSortMenu = styled.div`
  width: 100%;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    margin-left: auto;
    flex-direction: row;
    align-items: center;
  }
`;

const StyledSortMenuProductCountWrapper = styled.div`
  margin: 0 auto 0 0;
  padding-bottom: ${px2Rem(24)};

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    display: none;
  }
`;

const StyledSortMenuSortByLabel = styled.div`
  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    margin-right: ${px2Rem(16)};
  }
`;

const StyledFilterSortTrigger = styled.button<{ $isSticky: boolean }>`
  ${({ $isSticky }) => `
    border-radius: ${px2Rem(32)};
    background-color: ${palette.white};
    border: 0;
    outline: 1px solid ${palette.blue[300]};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: ${px2Rem(16)};
    margin: 0 0 0 auto;
    padding: ${px2Rem(10)} ${px2Rem(16)};
    position: ${$isSticky ? 'fixed' : 'unset'};
    top: ${$isSticky ? px2Rem(142) : 'auto'};
    right: ${$isSticky ? px2Rem(24) : 'auto'};
    box-shadow: ${$isSticky ? '0px 4px 24px 0px rgba(0, 0, 0, 0.10)' : 'none'};
    align-items: center;
    z-index: 99;

    &:focus {
      outline: 1px solid ${palette.blue[300]};
    }

    &:hover {
      outline: 2px solid ${palette.blue[300]};
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);
    }
  `}
`;

const StyledFilterSortTriggerIcon = styled.span`
  display: none;

  @media (min-width: ${NEW_BREAKPOINTS.mobile}px) {
    display: block;
  }
`;

const StyledSortSelectWrapper = styled.div`
  background-color: ${palette.white};
  width: 100%;
  padding-top: ${px2Rem(12)};

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    width: ${px2Rem(270)};
    padding-top: 0;
  }
`;

const StyledSortSelect = styled.select`
  appearance: none;
  cursor: pointer;
  display: block;
  /** Needed to override the default select styles */
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: ${px2Rem(14)};
  width: 100%;
  height: ${px2Rem(56)};
  padding: ${px2Rem(2)} ${px2Rem(56)} ${px2Rem(2)} ${px2Rem(12)};
  border: ${px2Rem(1)} solid ${palette.charcoal[300]};
  border-radius: 0;
  color: ${palette.charcoal[500]};
  background-color: ${palette.white};
  background-image: url('${ASSETS_SVGS_BASE_URL}/chevron-down.svg');
  background-repeat: no-repeat;
  background-size: ${px2Rem(24)} auto;
  background-position: right ${px2Rem(16)} center;

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: ${palette.charcoal[500]};
    background-color: ${palette.charcoal[100]};
  }
`;

export {
  StyledSortMenu,
  StyledSortMenuProductCountWrapper,
  StyledSortMenuSortByLabel,
  StyledFilterSortTrigger,
  StyledFilterSortTriggerIcon,
  StyledSortSelectWrapper,
  StyledSortSelect,
};
