import { useEffect, useMemo, useRef, useState } from 'react';
import { useProductsContext } from '../../hooks/useProductsContext';
import ProductCard from '../productcard';
import { StyledGridWrapper, StyledProductsGrid, StyledProductsGridFilterWrapper } from './styles';
import { IProductsGrid } from './types';
import FilterWrapper from '../ProductFilter/FilterWrapper/FilterWrapper';
import FilterHeader from '../ProductFilter/FilterHeader/FilterHeader';
import { Container, Copy } from 'uibook';
import { px2Rem } from '../../utils/px2Rem';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { useMediaLayout } from 'use-media';
import Breadcrumbs from '../BreadCrumbs/BreadCrumbs';
import { capitalize } from '@/utils/strings.utils';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';

export const ProductsGrid = ({ dataTestId }: IProductsGrid) => {
  const {
    filteredProducts,
    filters: { category },
  } = useProductsContext();

  const isMobileOrTablet = useMediaLayout({ maxWidth: NEW_BREAKPOINTS.smallDesktop });
  const [scrolledPastGrid, setScrolledPastGrid] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { hasLoggedInCustomer } = useCustomerContext();
  const { consumerTypePrefixPath } = useConsumerTypeContext();

  useEffect(() => {
    let observerRefValue: HTMLDivElement | null = null;

    const observer = new IntersectionObserver(([entry]) => {
      if (!isMobileOrTablet) return;
      setScrolledPastGrid(!entry.isIntersecting);
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
      observerRefValue = containerRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [containerRef, isMobileOrTablet]);

  const breadcrumbItems = useMemo(() => {
    let categoryName = category.length < 1 ? 'All products' : 'Products';

    if (category.length === 1) {
      categoryName = category[0] === 'tvs' ? 'TVs' : capitalize(category[0] || '');
    }
    return [
      {
        name: categoryName,
        url: consumerTypePrefixPath('/products'),
      },
    ];
  }, [category, consumerTypePrefixPath]);

  return (
    <>
      {!hasLoggedInCustomer && <Breadcrumbs items={breadcrumbItems} />}
      <Container maxWidth={px2Rem(1200)} margin="0 auto">
        <FilterHeader scrolledPastGrid={scrolledPastGrid} />
        <StyledGridWrapper data-testid={dataTestId} ref={containerRef}>
          <StyledProductsGridFilterWrapper>
            <FilterWrapper />
          </StyledProductsGridFilterWrapper>
          {!filteredProducts.length && (
            <Copy styles={{ textAlign: 'center' }}>
              No results found. Please update your filters and try again.
            </Copy>
          )}
          <div>
            <StyledProductsGrid data-testid="products-grid">
              {filteredProducts?.map(
                (
                  {
                    displayName,
                    variantImageUrl,
                    productVariantConditions,
                    variantSlug,
                    lowestMonthlyCost,
                    variantId,
                    cardDisplaySpecification,
                    make,
                    colours,
                    eligible,
                  },
                  productIndex,
                ) => (
                  <ProductCard
                    key={productIndex}
                    dataTestId={`product-card-${productIndex}`}
                    displayName={displayName}
                    imageUrl={variantImageUrl}
                    productVariantConditions={productVariantConditions}
                    lowestMonthlyCost={lowestMonthlyCost}
                    variantSlug={variantSlug}
                    cardDisplaySpecification={cardDisplaySpecification}
                    make={make}
                    variantId={variantId}
                    colours={colours}
                    eligible={eligible}
                    isPriorityImage={productIndex === 0}
                  />
                ),
              )}
            </StyledProductsGrid>
          </div>
        </StyledGridWrapper>
      </Container>
    </>
  );
};
